<template>
  <div class="container">

    <div class="row justify-content-center align-items-center mt-5 mb-5" style="min-height: calc(100vh - 6rem)">
      <div class="col-12 col-sm" style="max-width: 600px">

        <div class="mb-3 text-center">
          <h3 class=""> ขอบคุณ!</h3>
          <div class=""> คุณทำการสั่งซื้อเรียบร้อยเเล้ว</div>
        </div>

        <div class="card mb-5">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col font-weight-bold"> หมายเลขสั่งซื้อ {{ transactionId }}</div>
            </div>


            <div class="font-weight-bold"> รายละเอียดคำสั่งซื้อ</div>

            <div class="row">
              <div class="col text-secondary">ชื่อ-นามสกุล</div>
              <div class="col text-right"> {{ user.name }}</div>
            </div>

            <div class="row">
              <div class="col text-secondary">อีเมล</div>
              <div class="col text-right"> {{ user.email }}</div>
            </div>

            <div class="row mb-3">
              <div class="col text-secondary">เบอร์โทรศัพท์</div>
              <div class="col text-right"> {{ user.phone }}</div>
            </div>


            <div class="row no-gutters mb-5" style="border: 1px solid black">
              <div class="col-12  col-sm-4">
                <div class="card-body ">
                  <img class="img-fluid" :src="item.url_image"/>
                </div>
              </div>
              <div class="col">
                <div class="card-body">

                  <div>
                    {{ item.name }}
                  </div>
                  <div class="text-secondary" v-html="item.detail"></div>


                  <div class="row mt-5">
                    <div class="col">ราคา {{ item.price | number }} บาท</div>
                    <div class="col-auto">x {{ subTxn.length }} ชิ้น</div>
                  </div>
                  <div class="row font-weight-bold">
                    <div class="col">ยอดชำระทั้งหมด</div>
                    <div class="col-auto"> {{ totalPrice | number }} บาท</div>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <div v-if="item.type === 'Redeem Code'">
              <div class="mb-3 text-center">Promo Code ของคุณคือ</div>
              <div class="mb-3" v-for="(link, index) of links">
                <div class="row text-center justify-content-center align-items-center">
                  <div class="small col text-primary font-weight-bold mb-3 mb-sm-0">
                    {{ link }}
                  </div>
                  <div class="col-auto pr-1">
                    <div class="btn btn-primary" @click="copy(link)">
                      Copy
                    </div>
                  </div>
                  <div class="col-auto">
                    <a class="btn btn-primary" :href="link" target="_blank">
                      Open Web
                    </a>
                  </div>
                </div>
                <hr v-if="index < links.length - 1">
              </div>
            </div>

            <div class="text-center pt-5 pb-5" v-else>
              <h5 class="font-weight-bold"> การสั่งซื้อเรียบร้อยเเล้ว</h5>
              <div class="row justify-content-center">
                <div class="col-12 col-md-9">ได้รับคำสั่งซื้อของท่านเรียบร้อยเเล้วกำลังดำเนินการจัดส่ง
                  กรุณาแคปเจอร์หน้าจอนี้ไว้เพื่อเป็นหลักฐานในการสั่งซื้อ
                </div>
              </div>

            </div>


          </div>
        </div>


        <div>
          <a :href="item.url_how_to_redeem" target="_blank"
             class="w-100 btn btn-primary mb-3">
            ส่งข้อความ
          </a>

          <div class="text-center text-secondary">
            ส่งข้อความถึงทีมงานเพื่อสอบถามข้อมูลเพิ่มเติมเกี่ยวกับสินค้า
          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import {mapState} from 'vuex';
import config from '../config/constant'

export default {
  name: 'Success',
  components: {},
  computed: {
    ...mapState({
      item: state => state.payment.itemInfo,
      transactionId: state => state.payment.transactionId,
      subTxn: state => state.payment.subTxn,
      totalPrice: state => state.payment.totalPrice,
      verifyCode: state => state.payment.verifyCode,
      user: state => state.payment.userInfo
    }),
  },
  async created() {
    if (this.item.unique_id && this.verifyCode) {
      // gtag
      let dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'purchase success',
        'transactionId': this.transactionId,
        'user': this.user,
        'product': this.item
      });

      this.subTxn.map(txn => {
        this.links.push(`${config.GIFT_URL}?vf=${this.verifyCode}&txn=${txn}`)
      })
    } else {
      await this.$router.push('/message')
    }
  },
  data() {
    return {
      links: []
    }
  },
  methods: {
    copy(url) {
      this.$copyText(url).then(function (e) {
        console.log('copy');
      }, function (e) {
        console.log('can not copy');
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.icon-app {
  max-height: 70px;
  margin-bottom: 15px;
}

.icon-download {
  max-height: 30px;
}
</style>
